import React from "react";

const Header = () => {
    return (
        <header className="relative z-10 flex flex-col justify-center items-center bg-cover bg-center text-center text-white bg-gray-800 h-[700px] p-8" style={{ backgroundImage: `url("/pics/photo.jpg")` }}>
            <div className="bg-black bg-opacity-50 p-6 rounded">
                <h1 className="text-4xl font-bold">HURRICANE CONCRETE</h1>
                <p className="text-xl mt-4">Crafting the Future with Excellence</p>
            </div>
        </header>
    );
};

export default Header;
