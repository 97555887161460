import React from "react";

const AboutUs = () => {
    return (
        <section className="py-12 bg-gray-200">
            <div className="container mx-auto px-4">
                <h2 className="text-3xl font-bold text-center mb-6">About Us</h2>
                <div className="flex flex-wrap items-center">
                    <div className="md:w-1/2">
                        <img 
                            src="/pics/about.jpg" 
                            alt="About Us" 
                            className="rounded-lg shadow-md w-[500px] h-[500px] mx-auto" // Зменшена ширина та висота
                        />
                    </div>
                    <div className="md:w-1/2 mt-4 md:mt-0 italic md:pl-10">
                        <p className="text-lg">
                            At Hurricane Concrete, our team of skilled and experienced professionals ensures impeccable attention to detail and precision in every aspect of construction. We collaborate closely with clients to understand their vision and create spaces that seamlessly blend functionality and aesthetics.
                        </p>
                        <p className="mt-4">
                            We prioritize open communication and a transparent process throughout every project. Our dedicated customer service team is responsive, approachable, and committed to ensuring your satisfaction. We understand that construction projects evolve, and we are responsive to any changes or unforeseen circumstances that may arise.
                        </p>
                        <p className="mt-4">
                            So don’t hesitate to choose “Hurricane Concrete” as your trusted construction partner!
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default AboutUs;
