import React from 'react';

const WhyChooseUs = () => {
  const reasons = [
    {
      logo: '/pics/calendar.png',
      title: 'High Quality',
      description: " We guarantee high-quality work backed by years of experience and professional skills. Our team is composed of seasoned professionals who excel in their craft. With a strong track record of successful projects, we ensure top-notch results for every job."
    },
    {
      logo: '/pics/equipment.png',
      title: 'Reliability',
      description: " We always adhere to agreed timelines, ensuring projects are completed on time. Punctuality and reliability are core values that drive our work ethic. Clients can count on us to deliver results promptly without compromising quality."
    },
    {
      logo: '/pics/experience.png',
      title: 'Use of Modern Technology',
      description: "We employ modern equipment and cutting-edge technology to achieve the best results. Our innovative approach allows us to tackle complex projects efficiently. Staying updated with the latest advancements ensures we provide top-tier services."
    },
    {
      logo: '/pics/money.png',
      title: 'Competitive Prices',
      description: "We offer high-quality services at competitive prices, ensuring great value. Our pricing is transparent and designed to fit within your budget. Clients appreciate our balance of cost-effectiveness and exceptional workmanship."
    }
  ];

  return (
    <div className="py-12 bg-gray-300">
      <h2 className="text-3xl font-bold text-center mb-40">Why us</h2>
      <div className="flex justify-around flex-wrap">
        {reasons.map((reason, index) => (
          <div key={index} className="w-full md:w-1/4 p-4 flex flex-col items-center bg-gray-200 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <img src={reason.logo} alt={`Logo ${index + 1}`} className="h-24 mb-4"/>
            <h3 className="text-xl font-semibold mb-2">{reason.title}</h3>
            <p className="text-center">{reason.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WhyChooseUs;
