import React, { useState } from 'react';

const NavigMenus = ({ aboutUsRef, showcaseRef, footerRef, whyUsRef }) => {
  const [isOpen, setIsOpen] = useState(false);

  const scrollToRef = (ref) => {
    if (ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="bg-yellow-500 px-4 py-3 flex items-center justify-between rounded shadow-md">
      <img src="/pics/image.png" alt="Logo" className="h-12 w-12 mr-4" />
      <div className="md:hidden">
        <button onClick={() => setIsOpen(!isOpen)} className="text-black">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d={isOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16M4 18h16"}></path>
          </svg>
        </button>
      </div>
      <div className={`flex-col md:flex-row md:flex ${isOpen ? 'flex' : 'hidden'} w-full md:w-auto`}>
        <button className="mx-4 my-1 px-4 py-2 rounded text-lg font-bold" onClick={() => scrollToRef(whyUsRef)}>
          <span className="text-black bg-yellow-500 hover:text-yellow-700">Features</span>
        </button>
        <button className="mx-4 my-1 px-4 py-2 rounded text-lg font-bold" onClick={() => scrollToRef(aboutUsRef)}>
          <span className="text-black bg-yellow-500 hover:text-yellow-700">About Us</span>
        </button>
        <button className="mx-4 my-1 px-4 py-2 rounded text-lg font-bold" onClick={() => scrollToRef(showcaseRef)}>
          <span className="text-black bg-yellow-500 hover:text-yellow-700">Projects</span>
        </button>
        <button className="mx-4 my-1 px-4 py-2 rounded text-lg font-bold" onClick={() => scrollToRef(footerRef)}>
          <span className="text-black bg-yellow-500 hover:text-yellow-700">Contact</span>
        </button>
      </div>
    </div>
  );
};

export default NavigMenus;
