import React from "react";

const Yellow = () => {
    return (
        <header className="bg-yellow-600 py-12">
          <div className="bg-yellow-600 text-left text-white py-4 px-8">
            <h2 className="text-4xl font-bold">Welcome to Our Website</h2>
            <p className="text-2xl mt-2">We are dedicated to providing the best services in the industry.</p>
        </div>
        </header>
    );
};

export default Yellow;
