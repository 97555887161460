import React from "react";

const Footer = () => {
    return (
        <footer className="bg-gray-800 text-white py-6">
            <div className="container mx-auto px-4">
                <h3 className="text-lg font-bold mb-6 text-center md:text-left">Contact Information</h3>
                <div className="flex flex-wrap justify-between">
                    <div className="w-full md:w-1/3 mb-6 md:mb-0 px-4">
                        <p className="mb-4"><strong>Nikola:</strong> 289-887-7112</p>
                        <p className="mb-4"><strong>Maksym:</strong> 647-551-3343</p>
                    </div>
                    <div className="w-full md:w-1/3 mb-6 md:mb-0 px-4">
                        <p className="mb-4"><strong>Email Nikola:</strong> nikola22223@gmail.com</p>
                        <p className="mb-4"><strong>Email Maksym:</strong> tymsiv.m@gmail.com</p>
                    </div>
                    <div className="w-full md:w-1/3 px-4">
                        <p className="mb-4"><strong>Working hours:</strong></p>
                        <p>9 AM - 8 PM, 7 days a week</p>
                    </div>
                </div>
                <div className="flex justify-center mt-6">
                    <p className="text-sm text-gray-400">Website made by Tkachenko Artem</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
