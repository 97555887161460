import React, { useState, useMemo } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Showcase = () => {
    const [selectedCategory, setSelectedCategory] = useState('All');
    const [currentPage, setCurrentPage] = useState(1);
    const projectsPerPage = 6;
    const projects = [
        { image: "/pics/photo.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/stairs.jpg", category: "Stairs", orientation: "vertical" },
        { image: "/pics/stairs2.jpg", category: "Stairs", orientation: "vertical" },
        { image: "/pics/stairs4.jpg", category: "Stairs", orientation: "vertical" },
        { image: "/pics/stairs5.jpg", category: "Stairs", orientation: "vertical" },
        { image: "/pics/basement.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/basement2.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/basement3.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/basement4.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/basement5.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/basement6.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/driveway.jpg", category: "Other", orientation: "horizontal" },
        { image: "/pics/driveway2.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway3.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway4.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway5.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway6.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway7.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway8.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway9.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/driveway10.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/floor.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/floor2.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage2.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage3.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage4.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage5.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage6.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/garage7.jpg", category: "Garages", orientation: "horizontal" },
        { image: "/pics/idk.jpg", category: "Other", orientation: "horizontal" },
        { image: "/pics/idk2.jpg", category: "Other", orientation: "horizontal" },
        { image: "/pics/idk3.jpg", category: "Other", orientation: "horizontal" },
        { image: "/pics/idk4.jpg", category: "Other", orientation: "horizontal" },
        { image: "/pics/patio.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio2.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio3.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio4.jpg", category: "Basements", orientation: "horizontal" },
        { image: "/pics/patio5.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio6.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio7.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio8.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio9.jpg", category: "Patios", orientation: "horizontal" },
        { image: "/pics/patio10.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/patio11.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/patio12.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/patio13.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/patio14.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/porch.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/porch2.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/porch3.jpg", category: "Porches", orientation: "horizontal" },
        { image: "/pics/walkout.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkout2.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkout5.jpg", category: "DriveWays", orientation: "horizontal" },
        { image: "/pics/walkway1.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkway2.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkway3.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkway4.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkway5.jpg", category: "Walkways", orientation: "horizontal" },
        { image: "/pics/walkway6.jpg", category: "Walkways", orientation: "horizontal" },
    ];

    const categories = ['All', 'Patios', 'DriveWays', 'Basements', 'Garages', 'Porches', 'Stairs', 'Walkways', 'Other'];

    const filteredProjects = useMemo(() => {
        return selectedCategory === 'All' ? projects : projects.filter(project => project.category === selectedCategory);
    }, [selectedCategory, projects]);

    const indexOfLastProject = currentPage * projectsPerPage;
    const indexOfFirstProject = indexOfLastProject - projectsPerPage;
    const currentProjects = filteredProjects.slice(indexOfFirstProject, indexOfLastProject);
    const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <section className="py-12 bg-gray-300">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8">
                <h2 className="text-3xl font-bold text-center mb-6">Our Projects</h2>
                <div className="flex justify-center mb-8">
                    <div className="bg-white p-4 sm:p-6 lg:p-8 rounded shadow-md inline-block">
                        {categories.map((category) => (
                            <button
                                key={category}
                                className={`mx-2 mb-2 px-4 py-2 rounded text-lg font-bold border-2 ${selectedCategory === category ? 'bg-yellow-500 text-black border-yellow-500' : 'bg-white text-black border-gray-300'}`}
                                onClick={() => {
                                    setSelectedCategory(category);
                                    setCurrentPage(1); // Reset page to 1 when changing category
                                }}
                            >
                                {category}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                    {currentProjects.map((project, index) => (
                        <div key={index} className="relative">
                            <div className={`aspect-w-${project.orientation === "horizontal" ? 32 : 1} aspect-h-${project.orientation === "horizontal" ? 9 : 1}`}>
                                <LazyLoadImage
                                    effect="blur"
                                    src={project.image}
                                    alt={`Project ${index + 1}`}
                                    className="w-full h-full object-cover"
                                />
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex flex-wrap justify-center mt-8">
                    {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index + 1}
                            className={`m-1 px-3 py-2 rounded text-lg font-bold ${currentPage === index + 1 ? 'bg-yellow-500 text-black' : 'bg-white text-black'}`}
                            onClick={() => handleClick(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Showcase;
