import React, { useRef } from 'react';

import Header from './components/Header';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Showcase from './components/Showcase';
import NavigMenus from './components/NavigMenus.js';
import WhyChooseUs from './components/WhyChooseUs.js';
import Yellow from './components/Yellow.js';

function App() {
  const aboutUsRef = useRef(null);
  const showcaseRef = useRef(null);
  const footerRef = useRef(null);
  const whyUsRef = useRef(null);

  return (
    <div className='font-sans bg-gray-300'>
      <NavigMenus aboutUsRef={aboutUsRef} showcaseRef={showcaseRef} footerRef={footerRef} whyUsRef={whyUsRef} />
      <Header/>
      <Yellow/>
      <div ref={whyUsRef} className='mb-52'><WhyChooseUs/></div>
      <div ref={aboutUsRef} className='my-12'><AboutUs/></div>
      <div ref={showcaseRef} className='my-52'><Showcase/></div>
      <div ref={footerRef}><Footer/></div>
    </div>
  );
}

export default App;
